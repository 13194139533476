<template>
  <section>
      <div class="content-header">
        <h2>The science of flow</h2>
      </div>
      <div class="content-wrapper">
        <p>Here are three studies on flow.</p>
        <h4>Procrastination, flow, and academic performance in real time using the experience sampling method  </h4>
        <p><a href="https://pubmed.ncbi.nlm.nih.gov/29595377/" target="_blank">This study</a> aimed to analyze the relationship between procrastination, flow and achievement. The results were that regardless of the status of flow, both the flow and non-flow groups showed high levels of procrastination. However, students who experienced flow as they worked on their paper earned significantly higher grades.</p>
        <h4>Structural relationships among self-regulated learning, learning flow, satisfaction, and learning persistence in cyber universities</h4>
        <p><a href="https://www.tandfonline.com/doi/abs/10.1080/10494820.2012.745421" target="_blank">This study</a> analyzed the relationship between self-regulation, learning flow, satisfaction and persistence. The results showed that self-regulation directly affected flow, flow directly affected satisfaction, and satisfaction directly affected learning persistence in an online environment. </p>
        <h4>Impact of academic psychological capital on academic achievement among university undergraduates</h4>
        <p>Self-handicapping refers to constructing barriers to successful performance on the tasks that have great valence for individuals (e.g., failing to study for an exam, getting drunk the night before an exam, etc.). <a href="https://onlinelibrary.wiley.com/doi/10.1002/pchj.318" target="_blank">This study</a> used psychological capital (PsyCap) to improve the academic performance of students, and concluded that PsyCap decreased self-handicapping behaviours and enhanced flow experience, which led to improved academic achievement. </p>
      </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '03',
  components: {
    // SidebarMenu,
    // Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
